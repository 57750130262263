<template>
  <el-container type="flex" class="login-container" ref="loginContainer">
    <el-main class="bg">
      <!-- <img src="@/assets/map.svg" class="global"> -->
    </el-main>
    <el-aside width="400px" class="bg gaussian-blur" :style="gaussianBlurStyle">
    </el-aside>
    <el-aside width="400px" class="bg" >
        <el-form autoComplete="on" :model="loginForm" :rules="loginRules" ref="loginForm" label-position="left"
                 label-width="0px" class="login-form">
          <el-row class="title" justify="center">
            <h3>用户登录</h3>
          </el-row>
          <el-form-item prop="account">
            <el-input class="loginInput" name="account" type="text" v-model.trim="loginForm.account" autoComplete="on" placeholder="账号" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input class="loginInput" name="password" type="password" @keyup.enter="handleLogin" v-model.trim="loginForm.password"
                      autoComplete="on" show-password placeholder="密码"></el-input>
          </el-form-item>
          <el-row v-show="loginFailCount >= 5" type="flex" justify="space-between">
            <el-col :span="15">
              <el-form-item prop="captcha">
                <el-input class="loginInput" name="captcha" type="text" @keyup.enter="handleLogin" v-model.trim="loginForm.captcha"
                          autoComplete="off" placeholder="验证码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="loginInput">
                <img style="width:90px;height:40px;border:1px solid #fff;border-radius: 8px;" :src="captchaUrl" @click="refreshCaptcha()"
                     @error="onCaptchaError"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item class="without-border">
            <el-button type="primary" style="width:100%;background:linear-gradient(to right, #1ca5fa, #0c6cf2)" :loading="loading" @click.prevent="handleLogin">
              登录
            </el-button>
          </el-form-item>
        </el-form>
    </el-aside>
  </el-container>
</template>

<script>
import adminApi from '@/apis/admin/admin'
// import customerApi from '@/apis/customer/customer'
// import logo from '@/assets/logo.png'
import captchaError from '@/assets/img/captchaError.svg'
import {
  ElMessage
} from 'element-plus'
// import areaUsedPowerApi from '@/api/report/areaUsedPower'
// import { saveAs } from 'file-saver'
export default {
  name: 'login',
  props: ['lastPath'],
  data () {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入正确的账号'))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码长度需在6到20位之间'))
      } else if (value.length > 20) {
        callback(new Error('密码长度需在6到20位之间'))
      } else {
        callback()
      }
    }
    return {
      infoApi: null,
      systemName: this.$APP_NAME,
      // logo,
      gaussianBlurStyle: '',
      captchaUrl: `${this.$BASE_API}/com/captcha/getImageCaptcha/${
        this.randomId
      }`,
      loginForm: {
        account: '',
        password: '',
        captcha: '',
        randomId: ''
      },
      loginRules: {
        account: [
          { required: true, trigger: 'blur', validator: validateUsername }
        ],
        password: [{ required: true, trigger: 'blur', validator: validatePass }],
        // captcha: [{ required: true, message: '验证码必填' }]
      },
      loading: false,
      loginFailCount: 0
    }
  },
  methods: {
    onCaptchaError () {
      this.captchaUrl = captchaError
    },
    refreshCaptcha () {
      this.loginForm.randomId = Math.random()
      this.captchaUrl = `${this.$BASE_API}/com/captcha/getImageCaptcha/${this.loginForm.randomId}`
    },
    handleLogin () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          adminApi
            .login(this.loginForm)
            .then(resp => {
              const data = resp.data
              this.$store.dispatch('Login', data).then(() => {
                adminApi.getInfo().then(resp => {
                  const data = resp.data
                  if (!data.roles || data.roles.length <= 0) {
                    data.roles = [{ name: '无角色' }]
                  }
                  this.$store.dispatch('GetInfo', data).then(() => {
                    var { roles, menus } = data
                    this.loading = false
                    this.$store
                      .dispatch('GenerateRoutes', { roles, menus })
                      .then(() => {
                        this.$store.getters.addRouters.forEach(r =>  this.$router.addRoute(r))
                        //  this.$router.push({ path: '/home' })
                        if (data.menus.length > 0) {
                          for (var i = 0; i < data.menus.length; i++) {
                            if (data.menus[i].children && data.menus[i].children.length > 0) {
                              //this.$router.push({ name: data.menus[i].children[0].title }).catch(() => {})
                              this.$router.push({ path: data.menus[i].children[0].url }).catch(() => {})
                              break
                            }
                          }
                        } else {
                          this.$message.warning("你没有权限访问")
                        }
                      })
                  })
                }).catch(() => {
                    this.loading = false
                    this.refreshCaptcha()
                  })
                }).catch(() => {
                  this.loading = false
                })
              })
            .catch(() => {
              adminApi.getLoginFailCount(this.loginForm).then(resp => {
                this.loginFailCount = resp.data
              }).catch()
              this.loading = false
              this.refreshCaptcha()
            })
        } else {
          return false
        }
      })
      console.log(this.loginFailCount)
    },
    setWindowSize (width, height) {
    }
  },
  mounted () {
    this.gaussianBlurStyle = {
      'background-size': `${this.$refs.loginContainer.$el.clientWidth}px ${this.$refs.loginContainer.$el.clientHeight}px`
      // height: `${this.$refs.loginForm.$el.clientHeight}px`
    }
  },
  created () {
    this.loginForm.randomId = Math.random()
    this.captchaUrl = `${this.$BASE_API}/com/captcha/getImageCaptcha/${
      this.loginForm.randomId
    }`

    var that = this
    window.onresize = function () {
      that.gaussianBlurStyle = {
        'background-size': `${that.$refs.loginContainer.$el.clientWidth}px ${that.$refs.loginContainer.$el.clientHeight}px`
        // height: `${that.$refs.loginForm.$el.clientHeight}px`
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .login-container {
    height: 100vh;
    background-image: url('../../assets/login-bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
  }
  .bg {
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba($color: #144691, $alpha: 0.2);

    .global {
      width: 504px;
      height: 376px;
    }
  }
  aside.bg{
    background: rgba($color: #000, $alpha: 0.25);
  }
  aside.gaussian-blur {
      height: 100%;
      position: absolute;
      right: 0px;
      background-image: url('../../assets/login-bg.jpg');
      background: rgba($color: #000, $alpha: 0);
      background-repeat: no-repeat;
      // background-size: 100% 100%;
      filter: blur(5px);
      background-position: right;
    }
  .login-form {
    width: 264px;
  }
  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #e1e2e4 inset !important;
    -webkit-text-fill-color: #000 !important;
    background-color: #000 !important;
  }

  .title {
    font-size: 22px;
    text-align: center;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    // color: rgba(0,0,0,.85);
    color: rgba($color: #ffffff, $alpha: 1.0);
    line-height: 40px;
  }
  .loginInput {
    :deep(input){
      height:40px;
      border-radius: 8px;
      box-shadow: inset 0 0 0 1000px #2B3F5A!important;
      -webkit-box-shadow: 0 0 0px 1000px #2B3F5A inset;
      -webkit-text-fill-color: #ffffff;
      color: rgba($color: #ffffff, $alpha: 1.0);
      caret-color:#ffffff;
      border: none;
  }
  }
</style>

